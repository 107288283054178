<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink=".">Home</a>
      <a mat-list-item routerLink="/inicio">Inicio</a>
      <a mat-list-item routerLink="/blog">Blog</a>
      <a mat-list-item routerLink="/servicios">Servicios</a>
      <a mat-list-item routerLink="/clientes">Clientes</a>
      <a mat-list-item routerLink="/equipo">Equipo</a>
      <a mat-list-item routerLink="/contacto">Contacto</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>angular-web</span>
    </mat-toolbar>
    <!-- Add Content Here -->
    <!--
    <app-main-table></app-main-table>
  -->
  <!--
    <app-main-dash></app-main-dash>
-->

  </mat-sidenav-content>
</mat-sidenav-container>
