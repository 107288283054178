
<!--The content below is only a placeholder and can be replaced.-->
<!--
<div style="text-align:center">
  <h1>
    Welcome to {{ title }}!
  </h1>
  <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==">
</div>
<h2>Here are some links to help you start: </h2>
<ul>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://angular.io/tutorial">Tour of Heroes</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://angular.io/cli">CLI Documentation</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://blog.angular.io/">Angular blog</a></h2>
  </li>

  

</ul>
-->
<app-menu-principal></app-menu-principal>
<!--
<app-main-table></app-main-table>
-->
<!--
<app-main-table></app-main-table>
-->

<!--
<h3>Welcome to {{ title }}</h3>
<div class="example-button-row">
  <button mat-mini-fab aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <a mat-mini-fab routerLink=".">Home</a>
  <a mat-mini-fab routerLink="/inicio">Inicio</a>
  <a mat-mini-fab routerLink="/blog">Blog</a>
  <a mat-mini-fab routerLink="/servicios">Servicios</a>
  <a mat-mini-fab routerLink="/clientes">Clientes</a>
  <a mat-mini-fab routerLink="/equipo">Equipo</a>
  <a mat-mini-fab routerLink="/contacto">Contacto</a>
</div>
-->



<router-outlet></router-outlet>

